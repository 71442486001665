import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Import the js-cookie library
import { useDispatch } from "react-redux";
import { userLogout } from "../store/actions";

export const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleLogout = () => {
      dispatch(userLogout());
      Cookies.remove("sid");
      localStorage.clear();
      navigate("/");
    };
    handleLogout();
  }, [navigate]);
};

export default Logout;
