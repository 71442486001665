import {
  EXCEPTION_ERROR,
  FORGOT_USER_PASSWORD,
  FORGOT_USER_PASSWORD_SUCCESS,
  GET_GDRIVE_DETAILS,
  GET_GDRIVE_DETAILS_SUCCESS,
  GET_MENU_ITEMS,
  GET_MENU_ITEMS_SUCCESS,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  LOADING_START,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  POST_ADMIN_ROLE_ASSIGNMENT_DETAILS,
  POST_ADMIN_ROLE_ASSIGNMENT_DETAILS_SUCCESS,
  POST_GDRIVE_DETAILS,
  POST_GDRIVE_DETAILS_SUCCESS,
  POST_GDRIVE_SERVICE,
  POST_GDRIVE_SERVICE_SUCCESS,
  POST_USER_DETAILS,
  POST_USER_DETAILS_SUCCESS,
  RESET_EXCEPTION_ERROR,
  SESSION_API_ERROR,
  SESSION_EXPIRE_ERROR,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
  USER_LOGIN_INITIATE,
} from "./actionTypes";

export const exceptionError = (response) => {
  return {
    type: EXCEPTION_ERROR,
    ISError: response,
  };
};

export const loadingStart = () => {
  return {
    type: LOADING_START,
  };
};
export const exceptionAPIError = (response) => {
  return {
    type: SESSION_API_ERROR,
    apiError: response,
  };
};

export const resetExceptionError = () => {
  return { type: RESET_EXCEPTION_ERROR };
};

export const exceptionSessionExpire = (response) => {
  return {
    type: SESSION_EXPIRE_ERROR,
    sessionError: response,
  };
};

export const userLogin = (username, password, submitted, isSuperAdmin) => {
  console.log("actionindex", submitted);
  return {
    type: USER_LOGIN_INITIATE,
    username,
    password,
    submitted,
    isSuperAdmin,
  };
};

export const userLoginSuccess = (response, submitted) => {
  return {
    type: LOGIN_SUCCESS,
    userDetails: response,
    submitted,
  };
};
export const userLogout = () => {
  return {
    type: LOGOUT,
  };
};

export const userLogoutSuccess = (response) => {
  return {
    type: LOGOUT_SUCCESS,
    response,
  };
};
export const getUserDetails = () => {
  return {
    type: GET_USER_DETAILS,
  };
};

export const getUserDetailsSuccess = (response) => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    response,
  };
};

export const postUserDetails = (action, is_active, data) => {
  return {
    type: POST_USER_DETAILS,
    action,
    is_active,
    data,
  };
};

export const postUserDetailsSuccess = (response) => {
  return {
    type: POST_USER_DETAILS_SUCCESS,
    response,
  };
};
export const getMenuItems = (response) => {
  return {
    type: GET_MENU_ITEMS,
    response,
  };
};

export const getMenuItemsSuccess = (response) => {
  return {
    type: GET_MENU_ITEMS_SUCCESS,
    response,
  };
};
export const getUsers = (response) => {
  return {
    type: GET_USERS,
    response,
  };
};

export const getUsersSuccess = (response) => {
  return {
    type: GET_USERS_SUCCESS,
    response,
  };
};
export const postAdminRoleAssignmentDetails = (data) => {
  return {
    type: POST_ADMIN_ROLE_ASSIGNMENT_DETAILS,
    data,
  };
};

export const postAdminRoleAssignmentDetailsSuccess = (response) => {
  return {
    type: POST_ADMIN_ROLE_ASSIGNMENT_DETAILS_SUCCESS,
    response,
  };
};

export const getGdriveDetails = (response) => {
  return {
    type: GET_GDRIVE_DETAILS,
    response,
  };
};

export const getGdriveDetailsSuccess = (response) => {
  return {
    type: GET_GDRIVE_DETAILS_SUCCESS,
    response,
  };
};
export const postGdriveDetails = (data) => {
  return {
    type: POST_GDRIVE_DETAILS,
    data,
  };
};

export const postGdriveDetailsSuccess = (response) => {
  return {
    type: POST_GDRIVE_DETAILS_SUCCESS,
    response,
  };
};

export const postGdriveservice = (data) => {
  return {
    type: POST_GDRIVE_SERVICE,
    data,
  };
};
export const postGdriveserviceSuccess = (response) => {
  return {
    type: POST_GDRIVE_SERVICE_SUCCESS,
    response,
  };
};

export const userUpdatePassword = (new_password, key) => {
  return {
    type: UPDATE_USER_PASSWORD,
    new_password,
    key,
  };
};

export const userUpdatePasswordSuccess = (response) => {
  return {
    type: UPDATE_USER_PASSWORD_SUCCESS,
    response,
  };
};

export const userForgotPassword = (email, domain) => {
  return {
    type: FORGOT_USER_PASSWORD,
    email,
    domain,
  };
};

export const userForgotPasswordSuccess = (response) => {
  return {
    type: FORGOT_USER_PASSWORD_SUCCESS,
    response,
  };
};
